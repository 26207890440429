
.welcome-register-login{
   position: absolute !important;
   margin-top: 50vh;
   margin-bottom: auto;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   text-align: center;
   height: 100%;
   line-height: 38.4px;
   font-size: 24px;
   color: white;
   font-weight: 700;
}
.main-container-greeting{
    display:flex;
    justify-content: center;
    align-items: center;
    max-height: 120vh;
    min-height: 100vh;
    
}
 .main-container-register{
    background-color:white;
    font-family: 'Oswald';
 }

 .title-pages{
    font-family: 'Quicksand';  
    font-weight: 700;
    font-size : 32px;
    line-height: 51.2px;
    color: #32325D;
 }
 .sub-title-pages{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #8898AA;
    margin-bottom:25px;
}
.input-title{
    margin-bottom:4px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    /* line-height: 22px; */
    letter-spacing: 0.4px;
    color: #8898AA;
}
.input-custom{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;

/* CC Inactive */

color: #C1C1C1;
}
.input-group-custom{
    border: 1px solid #C1C1C1;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
}

.input-group-error{
    border: 1px solid #FF9D93;
}
.password-strength-text{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #8898AA;
    margin-top:8px;
}

.privacy-policy{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: 0.4px;
    
    color: #8898AA;
}
.button-create-register{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    background: #12ABC6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
    border-radius: 6px;
}
.social-media-text{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #C1C1C1 ;
}
.white-background{
    background: white;
}
.forgot-pass{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #12ABC6;
}
.btn-social-media{
    background: #FFFFFF;
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #525F7F;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
    border-radius: 6px;
}
.sign-in-text{
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #ADB5BD;
}
.positive-text{
    color:#3CE59C;
}    
.borderline-text{
    color:#FFD779;
}
.negative-text{
    color:#FF9D93;
}

.mb-title-pages{
    margin-bottom: 110px !important;
}

.dash-urgent-container{
    text-align: justify;
    margin:0px 10px 0px 10px;
     font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #525F7F;

}
.dash-urgent-text-coupon{
    color:#8898AA;
    font-weight: 400;
}
.mrgn-0{
margin:0px
}

.sign-name{
    font-size: 18px;
    margin: 0;
font-weight: 400;
}

.sign-title{
    color:#8898AA
}
.dash-btn-ok{
    color: white;
    background-color: #12ABC6;
}
.dashed-placeholder {
    border: 2px dashed #999;
    width: 100%;
    text-align-last: center;
    margin: 10px 5px 50px 5px;
    padding: 100px 0px 100px 0px;
}
.rightButton{
    float: right;
}
.section-style{
    width: -webkit-fill-available;
}